import { getPacificDateParts } from '@lib/utilities/date-utilities'
import { getDate, getDay, getHours, isSunday } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export type GeoData = {
  lat?: number
  lng?: number
  sublocality?: string
  city?: string
  state?: string
  postalCode?: string
  county?: string
  country?: string
  formattedAddress?: string
}
export type BrowserLocation = {
  latitude?: number
  longitude?: number
  status: 'prompt' | 'granted' | 'denied'
}

// Associated Press rules for displaying location adding commas where needed
// e.g.
// San Diego, CA 9212
// San Diego, CA,
// San Diego, CA
// San Diego,    9212
// San Diego,
// San Diego
//            CA 92123
//            CA 9212
//            CA,
//            CA
//               92123
//               9212
//
export const formatLocationString = (location?: GeoData): string => {
  let result = ''
  // remove city name if also found in sublocality
  if (location?.sublocality)
    result += `${location?.sublocality.replace(location?.city ?? '', '').trim()}`
  if (location?.city) result += `${result.length > 0 ? ', ' : ''}${location?.city}`
  if (location?.state) result += `${result.length > 0 ? ', ' : ''}${location?.state}`
  if (location?.postalCode) result += `${result.length > 0 ? ' ' : ''}${location?.postalCode}`
  return result
}

//  parses data from getGeoData
export const parseGeocodeResult = (result: any): GeoData => {
  const geoData: GeoData = {}
  const addressComponents = result.address_components
  const lat = parseFloat(result.geometry.location.lat())
  const lng = parseFloat(result.geometry.location.lng())

  if (!isNaN(lat) && !isNaN(lng)) {
    geoData.lat = lat
    geoData.lng = lng
    addressComponents.forEach((element: any) => {
      if (element.types.includes('postal_code')) {
        geoData.postalCode = element.short_name
      } else if (element.types.includes('neighborhood')) {
        geoData.sublocality = element.short_name
      } else if (element.types.includes('sublocality')) {
        geoData.sublocality = element.short_name
      } else if (element.types.includes('locality')) {
        geoData.city = element.short_name
      } else if (element.types.includes('administrative_area_level_1')) {
        geoData.state = element.short_name
      } else if (element.types.includes('country')) {
        geoData.country = element.short_name
      }
    })

    // don't use the x.formatted_address from Google, format it the way we want based on extracted base elements
    geoData.formattedAddress = formatLocationString(geoData)
  }

  return geoData
}

/**
 * Requests the user's current geographical location from the browser.
 * If geolocation is supported and permission is granted, returns an object with latitude, longitude, and status.
 * If permission is not granted or geolocation is not supported, returns an object with status 'denied'.
 */
export const requestBrowserLocation = (): Promise<BrowserLocation> => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const { latitude, longitude } = position.coords
              resolve({ latitude, longitude, status: 'granted' })
            },
            (error: GeolocationPositionError) => {
              console.log('troubleshoot requestBrowserLocation error:', error)
              console.error('Error getting browser location:', error)
              resolve({ status: 'denied' })
            }
          )
        } else {
          resolve({ status: 'denied' })
        }
      })
    } else {
      resolve({ status: 'denied' })
    }
  })
}

const methods = {
  formatLocationString,
  parseGeocodeResult,
  requestBrowserLocation,
}

export default methods
